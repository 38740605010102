<template>
  <div class="header-box">
    <div class="left-box">
      <div class="border"></div>
      <div class="title">
        <slot name="title" />
      </div>
    </div>
    <div class="right-box">
      <div class="input">
        <slot name="input" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: { },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {}
  },
  // 事件处理器
  methods: {},
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.header-box {
  width: 100%;
  min-height: 45px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  // overflow: hidden;

  .left-box {
    // float: left;
    margin-top: 7px;
    min-width: 170px;
    // min-width: 150px;
    .border {
      display: inline-block;
      margin-right: 2px;
      width: 2px;
      height: 10px;
      background: #3681f0;
      vertical-align: middle;
    }
    .title {
      display: inline-block;
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 19px;
    }
    .menu {
      display: inline-block;
    }
  }
  .right-box {
    flex: 1;
    // float: right;
    // width: calc(100% - 150px);
    .input {
      float: right;
      .tag {
        margin-right: 2px;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 14px;
      }

      .ant-input,.ant-calendar-picker,.ant-input-affix-wrapper,.ant-input-affix-wrapper {
        margin: 0 24px 14px 0;
        width: 240px;
        height: 30px;
      }

      .ant-select {
        margin: 0 24px 14px 0;
        width: 193px;
      }

      /deep/.ant-select-selection--single {
        height: 30px;
      }
    }
  }
}
</style>
