import { render, staticRenderFns } from "./HeaderBox.vue?vue&type=template&id=03894653&scoped=true&"
import script from "./HeaderBox.vue?vue&type=script&lang=js&"
export * from "./HeaderBox.vue?vue&type=script&lang=js&"
import style0 from "./HeaderBox.vue?vue&type=style&index=0&id=03894653&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03894653",
  null
  
)

export default component.exports